import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Menu,
  MenuList,
  Avatar,
  MenuItem,
  MenuHandler,
  IconButton,
  Drawer,
} from '@material-tailwind/react';
import {
  UserCircleIcon,
  InboxIcon,
  AdjustmentsHorizontalIcon,
  XMarkIcon,
  KeyIcon,
  ArrowLeftStartOnRectangleIcon,
  UserGroupIcon,
  NewspaperIcon,
  FolderIcon,
} from '@heroicons/react/24/solid';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { getCookie, refreshTokenNew, removeCookie } from '../../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import { listProductIDAllCount } from '../../services/apiItems';

function DashboardLayout({ children }) {
  const [open, setOpen] = useState(0);
  const [openDrawers, setOpenDrawers] = useState(false);
  const [countChoDuyet, setCountChoDuyet] = useState(0);
  const auth = getCookie('token');
  const navigate = useNavigate();
  refreshTokenNew();

  const openDrawer = () => setOpenDrawers(true);
  const closeDrawer = () => setOpenDrawers(false);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(() => {
    document.title = 'Hệ Thống';
  }, []);

  return (
    <div className="m-auto p-1 bg-[#fffdf4]">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-x-3">
        {/* Sidebar */}
        <div className="hidden lg:block self-start sticky top-1 h-[calc(100vh-1rem)] overflow-y-scroll">
          <div className=" w-full">
            <div className="p-2 ">
              <div className="flex justify-center">
                <Link to="/dashboard">
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="font-[UTM-Avo] text-orange-300 text-lg whitespace-nowrap cursor-pointer"
                  >
                    HỆ THỐNG
                  </Typography>
                </Link>
              </div>
              <div className="mt-4 flex items-center justify-center">
                <Menu>
                  <MenuHandler>
                    <Avatar variant="circular" alt="tania andrew" className="cursor-pointer" src={images.avt} />
                  </MenuHandler>
                  <MenuList>
                    <Link to="/user">
                      <MenuItem className="flex items-center gap-2">
                        <UserCircleIcon className="h-5 w-5" />

                        <Typography variant="small" className="font-medium">
                          Thông tin
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/changepass">
                      <MenuItem className="flex items-center gap-2">
                        <KeyIcon className="w-5 h-5" />

                        <Typography variant="small" className="font-medium">
                          Đổi mật khẩu
                        </Typography>
                      </MenuItem>
                    </Link>
                    <hr className="my-2 border-blue-gray-50" />
                    <MenuItem className="flex items-center gap-2 ">
                      <ArrowLeftStartOnRectangleIcon className="w-5 h-5" />
                      <Typography
                        variant="small"
                        className="font-medium"
                        onClick={() => {
                          removeCookie('token');
                          removeCookie('token_sv');
                          removeCookie('exp_sv');
                          navigate('/login', { replace: true });
                        }}
                      >
                        Đăng xuất
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Menu>
                <label className="text-base ml-4">
                  <b>{auth?.name}</b>
                  <br />
                  <i className="text-sm">
                    (
                    {auth?.permission === 1
                      ? 'Quản Trị'
                      : auth?.permission === 2
                      ? 'Kiểm Duyệt Viên'
                      : auth?.permission === 3
                      ? 'Quản Lý'
                      : 'Sale'}
                    )
                  </i>
                </label>
              </div>
            </div>
            <hr className="my-2 border-blue-gray-50" />
            <List className="min-w-0">
              {/* {auth?.permission === 1 && (
                <Accordion
                  open={open === 1}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={` mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`}
                    />
                  }
                >
                  <ListItem className="p-0" selected={open === 1}>
                    <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3 min-w-0">
                      <ListItemPrefix>
                        <UserGroupIcon className="h-5 w-5 " />
                      </ListItemPrefix>
                      <Typography color="blue-gray" className="mr-auto font-normal ">
                        Q.L Nhân Viên
                      </Typography>
                    </AccordionHeader>
                  </ListItem>
                  <Link to="/users">
                    <AccordionBody className="py-1">
                      <List className="p-0 min-w-0">
                        <ListItem>
                          <ListItemPrefix>
                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                          </ListItemPrefix>
                          Thêm nhân viên
                        </ListItem>
                      </List>
                    </AccordionBody>
                  </Link>
                  <Link to="/dsusers">
                    <AccordionBody className="py-1">
                      <List className="p-0 min-w-0">
                        <ListItem>
                          <ListItemPrefix>
                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                          </ListItemPrefix>
                          Danh sách nhân viên
                        </ListItem>
                      </List>
                    </AccordionBody>
                  </Link>
                </Accordion>
              )} */}

              <Accordion
                open={open === 2}
                icon={
                  <ChevronDownIcon
                    strokeWidth={2.5}
                    className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? 'rotate-180' : ''}`}
                  />
                }
              >
                <ListItem className="p-0" selected={open === 2}>
                  <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                    <ListItemPrefix>
                      <NewspaperIcon className="h-5 w-5 " />
                    </ListItemPrefix>
                    <Typography color="blue-gray" className="mr-auto font-normal ">
                      Q.L Sản Phẩm
                    </Typography>
                  </AccordionHeader>
                </ListItem>
                <Link to="/additems">
                  <AccordionBody className="py-1">
                    <List className="p-0  min-w-0">
                      <ListItem className="">
                        <ListItemPrefix>
                          <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        Thêm Sản Phẩm
                      </ListItem>
                    </List>
                  </AccordionBody>
                </Link>
                <Link to="/dsitems">
                  <AccordionBody className="py-1">
                    <List className="p-0  min-w-0">
                      <ListItem className="">
                        <ListItemPrefix>
                          <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        Danh sách Sản Phẩm
                      </ListItem>
                    </List>
                  </AccordionBody>
                </Link>
              </Accordion>
              <hr className="my-2 border-blue-gray-50" />

              <Link to="/menu">
                <ListItem className="">
                  <ListItemPrefix>
                    <InboxIcon className="h-5 w-5" />
                  </ListItemPrefix>
                  Quản lý menu
                </ListItem>
              </Link>

              {auth?.permission === 1 && (
                <div>
                  <hr className="my-2 border-blue-gray-50" />
                  <Accordion
                    open={open === 3}
                    icon={
                      <ChevronDownIcon
                        strokeWidth={2.5}
                        className={` mx-auto h-4 w-4 transition-transform ${open === 3 ? 'rotate-180' : ''}`}
                      />
                    }
                  >
                    <ListItem className="p-0 " selected={open === 3}>
                      <AccordionHeader onClick={() => handleOpen(3)} className="border-b-0 p-3">
                        <ListItemPrefix>
                          <FolderIcon className="h-5 w-5 " />
                        </ListItemPrefix>
                        <Typography color="blue-gray" className="mr-auto font-normal ">
                          Q.L Danh Mục
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <Link to="/cate">
                      <AccordionBody className="py-1">
                        <List className="p-0  min-w-0">
                          <ListItem className="">
                            <ListItemPrefix>
                              <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                            </ListItemPrefix>
                            Q.L Thể Loại
                          </ListItem>
                        </List>
                      </AccordionBody>
                    </Link>
                  </Accordion>
                </div>
              )}
            </List>
          </div>
        </div>

        {/* content */}
        <div className="lg:col-span-5 sm:col-span-6">
          <Card className="lg:hidden grid  w-full p-2 mb-2 bg-[#fffdf4]">
            <div className="flex justify-between items-center">
              <div>
                <IconButton variant="outlined" className="text-lg" onClick={openDrawer}>
                  <AdjustmentsHorizontalIcon className="h-5 w-5" />
                </IconButton>
              </div>
              <div>
                <Typography variant="h5" color="blue-gray" className="font-[UTM-Avo] text-orange-600">
                  HỆ THỐNG
                </Typography>
              </div>
              <div>
                {' '}
                <Menu>
                  <MenuHandler>
                    <Avatar variant="circular" alt="tania andrew" className="cursor-pointer" src={images.avt} />
                  </MenuHandler>
                  <MenuList>
                    <Link to="/user">
                      <MenuItem className="flex items-center gap-2">
                        <UserCircleIcon className="h-5 w-5" />

                        <Typography variant="small" className="font-medium">
                          Thông tin
                        </Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/changepass">
                      <MenuItem className="flex items-center gap-2">
                        <KeyIcon className="w-5 h-5" />

                        <Typography variant="small" className="font-medium">
                          Đổi mật khẩu
                        </Typography>
                      </MenuItem>
                    </Link>
                    <hr className="my-2 border-blue-gray-50" />
                    <MenuItem className="flex items-center gap-2 ">
                      <ArrowLeftStartOnRectangleIcon className="w-5 h-5" />
                      <Typography
                        variant="small"
                        className="font-medium"
                        onClick={() => {
                          removeCookie('token');
                          removeCookie('token_sv');
                          removeCookie('exp_sv');
                          navigate('/login', { replace: true });
                        }}
                      >
                        Đăng xuất
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </Card>
          <Card className="p-2 h-[calc(100vh-1rem)] overflow-y-scroll shadow-lg">{children}</Card>
        </div>

        <Drawer open={openDrawers} onClose={closeDrawer} className="p-4">
          <div className="mb-6 flex items-center justify-between">
            <Typography variant="h5" color="blue-gray">
              DANH MỤC
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon className="h-5 w-5" />
            </IconButton>
          </div>
          <hr className="my-2 border-blue-gray-50" />
          <List className="min-w-0">
            {/* {auth?.permission === 1 && (
                <Accordion
                  open={open === 1}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={` mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`}
                    />
                  }
                >
                  <ListItem className="p-0" selected={open === 1}>
                    <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3 min-w-0">
                      <ListItemPrefix>
                        <UserGroupIcon className="h-5 w-5 " />
                      </ListItemPrefix>
                      <Typography color="blue-gray" className="mr-auto font-normal ">
                        Q.L Nhân Viên
                      </Typography>
                    </AccordionHeader>
                  </ListItem>
                  <Link to="/users">
                    <AccordionBody className="py-1">
                      <List className="p-0 min-w-0">
                        <ListItem>
                          <ListItemPrefix>
                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                          </ListItemPrefix>
                          Thêm nhân viên
                        </ListItem>
                      </List>
                    </AccordionBody>
                  </Link>
                  <Link to="/dsusers">
                    <AccordionBody className="py-1">
                      <List className="p-0 min-w-0">
                        <ListItem>
                          <ListItemPrefix>
                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                          </ListItemPrefix>
                          Danh sách nhân viên
                        </ListItem>
                      </List>
                    </AccordionBody>
                  </Link>
                </Accordion>
              )} */}

            <Accordion
              open={open === 2}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? 'rotate-180' : ''}`}
                />
              }
            >
              <ListItem className="p-0" selected={open === 2}>
                <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                  <ListItemPrefix>
                    <NewspaperIcon className="h-5 w-5 " />
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal ">
                    Q.L Sản Phẩm
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <Link to="/additems" onClick={closeDrawer}>
                <AccordionBody className="py-1">
                  <List className="p-0  min-w-0">
                    <ListItem className="">
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Thêm Sản Phẩm
                    </ListItem>
                  </List>
                </AccordionBody>
              </Link>
              <Link to="/dsitems" onClick={closeDrawer}>
                <AccordionBody className="py-1">
                  <List className="p-0  min-w-0">
                    <ListItem className="">
                      <ListItemPrefix>
                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                      </ListItemPrefix>
                      Danh sách Sản Phẩm
                    </ListItem>
                  </List>
                </AccordionBody>
              </Link>
            </Accordion>
            <hr className="my-2 border-blue-gray-50" />

            <Link to="/menu" onClick={closeDrawer}>
              <ListItem className="">
                <ListItemPrefix>
                  <InboxIcon className="h-5 w-5" />
                </ListItemPrefix>
                Quản lý menu
              </ListItem>
            </Link>

            {auth?.permission === 1 && (
              <div>
                <hr className="my-2 border-blue-gray-50" />
                <Accordion
                  open={open === 3}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={` mx-auto h-4 w-4 transition-transform ${open === 3 ? 'rotate-180' : ''}`}
                    />
                  }
                >
                  <ListItem className="p-0 " selected={open === 3}>
                    <AccordionHeader onClick={() => handleOpen(3)} className="border-b-0 p-3">
                      <ListItemPrefix>
                        <FolderIcon className="h-5 w-5 " />
                      </ListItemPrefix>
                      <Typography color="blue-gray" className="mr-auto font-normal ">
                        Q.L Danh Mục
                      </Typography>
                    </AccordionHeader>
                  </ListItem>
                  <Link to="/cate" onClick={closeDrawer}>
                    <AccordionBody className="py-1">
                      <List className="p-0  min-w-0">
                        <ListItem className="">
                          <ListItemPrefix>
                            <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                          </ListItemPrefix>
                          Q.L Thể Loại
                        </ListItem>
                      </List>
                    </AccordionBody>
                  </Link>
                </Accordion>
              </div>
            )}
          </List>
        </Drawer>
      </div>
    </div>
  );
}

export default DashboardLayout;
