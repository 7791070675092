import { getSV, postSV } from '../utils/https';

// CATEGORY
export const addCategory = async (data) => {
  try {
    const res = await postSV(`/addcategory`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateCategory = async (data) => {
  try {
    const res = await postSV(`/updatecategory`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeCategory = async (data) => {
  try {
    const res = await postSV(`/deletecategoryid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllCategory = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listcategoryall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdCategory = async (id) => {
  try {
    const res = await getSV(`/listcategoryid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllCategory = async () => {
  try {
    const res = await getSV(`/listcategoryallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// MENU
export const addMenu = async (data) => {
  try {
    const res = await postSV(`/addmenu`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateMenu = async (data) => {
  try {
    const res = await postSV(`/updatemenu`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeMenu = async (data) => {
  try {
    const res = await postSV(`/deletemenuid`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllMenu = async (page = 1, size = 50) => {
  try {
    const res = await getSV(`/listmenuall?page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdMenu = async (id) => {
  try {
    const res = await getSV(`/listmenuid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllMenu = async () => {
  try {
    const res = await getSV(`/listmenuallcount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
