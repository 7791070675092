import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from './urls';

const requestAPIClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const get = async (path, options = {}) => {
  const response = await requestAPIClient.get(path, options);
  return response.data;
};

export const post = async (path, options = {}) => {
  const response = await requestAPIClient.post(path, options);
  return response.data;
};

// API SERVER

const requestAPIServer = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: Cookies.get('token_sv'),
  },
});

export const getSV = async (path, options = {}) => {
  const response = await requestAPIServer.get(path, options);
  return response.data;
};

export const postSV = async (path, options = {}) => {
  const response = await requestAPIServer.post(path, options);
  return response.data;
};
export const postUpload = async (path, options = {}) => {
  const response = await requestAPIServer.post(path, options, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
