import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Drawer,
  IconButton,
  Typography,
} from '@material-tailwind/react';
import images from '../../../assets/images';
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { listAllCategory } from '../../../services/apiCategory';
import { listProductIDAll, listProductIDAllCount } from '../../../services/apiItems';
import { getLocalStorage, numberFormat, setLocalStorage } from '../../../utils/helper';
import { API_URL } from '../../../utils/urls';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

export default function Home() {
  const [arrCate, setArrCate] = useState([]);
  const [resultListItems, setResultListItems] = useState({});
  const [countItems, setCountItems] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [idAddShop, setIdAddShop] = useState(0);

  const getCategory = async () => {
    const resultAPI = await listAllCategory(1, 1000);
    if (resultAPI.length > 0) {
      setArrCate(resultAPI);
      const promises = resultAPI.map(async (row) => {
        const result = await listItemsToCate(1, 4, '1', row.id);
        return { [row.id]: result };
      });
      const results = await Promise.all(promises);
      setResultListItems(results);

      const promisesCount = resultAPI.map(async (row) => {
        const result = await listItemsToCateCount('1', row.id);
        return { [row.id]: result.totalRows };
      });
      const resultsCount = await Promise.all(promisesCount);
      setCountItems(resultsCount);
    }
  };

  const listItemsToCate = async (pages = 1, size = 4, stt = '1', cate = '') => {
    let arrList = [];
    const resultArrAll = await listProductIDAll(pages, size, stt, cate);
    if (resultArrAll.length > 0) {
      arrList = resultArrAll;
    }
    return arrList;
  };
  const listItemsToCateCount = async (stt = '1', cate = '') => {
    let arrList = [];
    const resultArrAll = await listProductIDAllCount(stt, cate);
    if (resultArrAll.totalRows > 0) {
      arrList = resultArrAll;
    }
    return arrList;
  };

  useEffect(() => {
    getCategory();
  }, []);

  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, gia: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 1000);
  };
  return (
    <div>
      <div
        className="-top-[247px] bg-[#3d3d3d]"
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        <div
          // className="-top-[140px] bg-[#3d3d3d]"
          style={{
            backgroundImage: `url(${images.banner})`,
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            filter: 'brightness(0.8)',
            backgroundColor: '#fff',
          }}
        ></div>
      </div>

      {/* NỘI DUNG */}
      <div className="m-auto">
        {arrCate.length > 0 &&
          arrCate?.map((row, i) => (
            <div
              key={i}
              className={`grid justify-center justify-items-center ${i === 0 ? '-mt-[200px]' : ''} ${
                i / 2 ? 'bg-green-50' : ''
              }  py-4`}
            >
              <h2 className="text-2xl lg:text-4xl font-bold text-green-700 mb-10">{row.name}</h2>
              <div className="container m-auto">
                <div className="grid grid-cols-1  lg:grid-cols-4 gap-3">
                  {resultListItems.length > 0 &&
                    resultListItems[i][row?.id]?.map((item, index) => (
                      <div className="p-4" key={index}>
                        <Card>
                          <CardHeader color="blue-gray" className="relative">
                            <LazyLoadImage
                              alt={'không có ảnh'}
                              width={'100%'}
                              effect="blur"
                              src={`${
                                item?.images?.split(',').length > 0
                                  ? `${API_URL}/imgshop?name=${item?.images?.split(',')[0]}`
                                  : images.logo
                              }`}
                              onError={(e) => {
                                e.target.src = images.logo;
                              }}
                              className="rounded-xl"
                            />
                          </CardHeader>
                          <CardBody>
                            <div className="mb-2 font-[UTM-Avo] line-clamp-4 lg:line-clamp-2 font-bold">
                              {item?.title}
                            </div>
                            <Typography variant="h5" color="red" className="font-[UTM-Avo]">
                              {numberFormat(item?.gia)} đ
                            </Typography>
                          </CardBody>
                          <CardFooter className="pt-0 gap-2 flex ">
                            <Link to={`/san-pham/${item?.id}`}>
                              <Button color="orange" size="sm">
                                Xem chi tiết
                              </Button>
                            </Link>
                            <Button
                              color="green"
                              size="sm"
                              onClick={() => {
                                onAddShop(item?.title, numberFormat(item.gia), item?.cate, 1, 'KL' + item?.id);
                                setIdAddShop(item?.id);
                              }}
                            >
                              <ShoppingCartIcon className="h-4 w-4" />
                            </Button>
                          </CardFooter>
                          {openCart && idAddShop === item?.id && (
                            <div className="mb-2">
                              <Alert variant="ghost" color="green">
                                <b className="text-sm">Đã thêm vào giỏ hàng</b>
                              </Alert>
                            </div>
                          )}
                        </Card>
                      </div>
                    ))}{' '}
                </div>
              </div>
              {countItems.length > 0 && countItems[i][row?.id] > 4 && (
                <Link to={`/san-pham-shop/${row?.id}`}>
                  <div className="flex justify-end px-10 mb-4">
                    <Button color="green">Xem thêm</Button>
                  </div>
                </Link>
              )}
              {/* <hr className="my-3 border-blue-gray-100 w-full" /> */}
            </div>
          ))}
      </div>
    </div>
  );
}
