import { BellAlertIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Alert, Button, Card, Input, Textarea } from '@material-tailwind/react';
import { useState, useEffect, useRef } from 'react';
import { convertNumberToWords, getCookie } from '../../../utils/helper';
import * as imgDF from '../../../assets/images/index';
import { listAllCategory } from '../../../services/apiCategory';
import { API_URL } from '../../../utils/urls';
import { addItems, getIdItems, updateItems } from '../../../services/apiItems';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AddItems() {
  const inputFile = useRef(null);
  const auth = getCookie('token');
  const [open, setOpen] = useState(0);
  const [checkInfo, setCheckInfo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [imageURLsUpdate, setImageURLsUpdate] = useState([]);
  const [imageRemove, setImageRemove] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');
  const [arrItems, setArrItems] = useState({
    user_id: auth?.id,
    title: '',
    gia: '',
    soluong: 1,
    note: '',
    cate_id: '',
    status: '1',
    content: '',
    images: '',
  });

  const [arrOptionSelect, setArrOptionSelect] = useState({});
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const idItem = urlParams.get('u');

  useEffect(() => {
    document.title = 'Bài viết Sản Phẩm';
  }, []);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const getCategory = async () => {
    const resultAPI = await listAllCategory(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, category: resultAPI });
    }
  };

  const reset = () => {
    setImages([]);
    setImageURLs([]);
    setImageURLsUpdate([]);
    setImageRemove([]);
    setArrItems({
      user_id: auth?.id,
      title: '',
      gia: '',
      soluong: 1,
      note: '',
      cate_id: '',
      status: '1',
      content: '',
      images: '',
    });
  };

  const getItemsId = async (id) => {
    const resultID = await getIdItems(id);
    if (resultID.length > 0) {
      setCheckInfo(true);
      setArrItems({
        id: resultID[0].id,
        user_id: auth?.id,
        title: resultID[0].title,
        gia: resultID[0].gia,
        soluong: resultID[0].soluong,
        note: resultID[0].note,
        cate_id: resultID[0].cate_id,
        status: resultID[0].status,
        content: resultID[0].content,
        images: resultID[0].images,
      });
      setImageURLsUpdate(resultID[0].images?.split(','));
    } else {
      setCheckInfo(false);
    }
  };

  useEffect(() => {
    if (idItem) {
      getItemsId(idItem);
    }
    getCategory();
  }, [idItem]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const subUpdateDefault = async (e) => {
    e.preventDefault();
    if (arrItems.title === '') {
      setWarningMessage('Bạn chưa nhập tiêu đề');
    } else {
      setLoading(true);
      setIsDisabled(true);
      const formData = new FormData();
      let arrAnh = [...imageURLsUpdate];
      images.forEach((image, i) => {
        const extension = image.name.split('.').pop();
        let timestamp = i + '_img_' + Date.now() + '.' + extension;
        arrAnh.push(timestamp);
        formData.append('image', image, timestamp);
      });

      let arrITEMSSV = { ...arrItems, images: arrAnh.join(',') };
      formData.append('data', JSON.stringify(arrITEMSSV));
      formData.append('imgremove', JSON.stringify(imageRemove));
      const upload = await updateItems(formData);
      if (upload.message === 'OK') {
        reset();
        navigate('/dsitems');
      } else {
        setLoading(false);
        setIsDisabled(false);
      }
    }
  };
  const subAddDefault = async (e) => {
    e.preventDefault();
    if (arrItems.title === '') {
      setWarningMessage('Bạn chưa nhập tiêu đề');
    } else {
      setLoading(true);
      setIsDisabled(true);
      const formData = new FormData();
      let arrAnh = [];
      images.forEach((image, i) => {
        const extension = image.name.split('.').pop();
        let timestamp = i + '_img_' + Date.now() + '.' + extension;
        arrAnh.push(timestamp);
        formData.append('image', image, timestamp);
      });
      let arrITEMSSV = { ...arrItems, images: arrAnh.join(',') };
      formData.append('data', JSON.stringify(arrITEMSSV));
      const upload = await addItems(formData);
      if (upload.message === 'OK') {
        scrollToTop();
        reset();
        setLoading(false);
        setIsDisabled(false);
        setMessage('Thành Công');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setLoading(false);
        setIsDisabled(false);
        setMessage('Thất Bại');
      }
      setWarningMessage('');
    }
  };

  // HÌNH ẢNH
  const onButtonClickFileImage = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onFilechange = (e) => {
    let tempArr = [];
    let tempFileArr = [];
    if (images.length + imageURLsUpdate.length + e.target.files.length > 10) {
      alert('Bạn chỉ được thêm tối đa 10 ảnh');
      return;
    }
    [...e.target.files].forEach((file) => {
      if (file.type.startsWith('image/')) {
        tempArr.push(URL.createObjectURL(file));
        tempFileArr.push(file);
      } else {
        alert('Bạn không được thêm video hoặc tập tin');
        return;
      }
    });
    setImageURLs([...imageURLS, ...tempArr]);
    setImages([...images, ...tempFileArr]);
  };

  // xoa ảnh
  const removeImage = (index) => {
    const arrRemove = imageURLS.filter((_, i) => i !== index) || [];
    const arrRemoveFile = images.filter((_, i) => i !== index) || [];
    setImageURLs(arrRemove);
    setImages(arrRemoveFile);
  };
  const removeImageUpdate = (index) => {
    const arrRemove = imageURLsUpdate.filter((_, i) => i !== index) || [];
    const idRemove = imageURLsUpdate.filter((_, i) => i === index) || [];
    setImageRemove([...imageRemove, ...idRemove]);
    setImageURLsUpdate(arrRemove);
  };

  const typeImg = (str) => {
    const lastIndex = str.lastIndexOf('.');
    const result = str.substring(lastIndex + 1);
    if (result === 'png' || result === 'jpg' || result === 'jpeg') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="grid grid-cols-1">
      {message === 'Thành Công' && (
        <Alert
          icon={<BellAlertIcon className="h-5 w-5" />}
          className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946] flex items-center"
        >
          <b>THÊM TIN THÀNH CÔNG</b>
        </Alert>
      )}
      <div>
        <Card className="p-2">
          <h3 className="font-bold mb-2">THÊM BÀI VIẾT SẢN PHẨM</h3>
          <Alert
            color={message === 'Thành Công' ? 'green' : 'red'}
            className="mb-2"
            open={openAlert}
            onClose={() => setOpenAlert(false)}
          >
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
          <div className="grid gap-4">
            <Input
              value={arrItems?.title}
              placeholder="Thêm Tiêu Đề"
              required
              className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              onChange={(e) => {
                setArrItems({ ...arrItems, title: e.target.value });
              }}
            />

            {/* CHI TIẾT SẢN PHẨM */}
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 border-gray-400 p-2">Chi tiết Sản Phẩm</div>
              <div className="grid grid-cols-1 p-3">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Số lượng</b>
                    </label>

                    <Input
                      value={arrItems?.soluong}
                      placeholder="Nhập số lượng sản phẩm"
                      type="number"
                      required
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrItems({ ...arrItems, soluong: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Giá sản phẩm</b>
                    </label>

                    <Input
                      value={arrItems?.gia}
                      placeholder="Nhập giá sản phẩm"
                      required
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrItems({ ...arrItems, gia: e.target.value });
                      }}
                    />
                    <b className="text-red-600 text-sm">{convertNumberToWords(arrItems?.gia)}</b>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Thể loại</b>
                    </label>
                    <div className="relative mb-2">
                      <select
                        value={`${arrItems?.cate_id}`}
                        onChange={(e) => {
                          setArrItems({ ...arrItems, cate_id: e.target.value });
                        }}
                        className="w-full border-gray-400 rounded-xl bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                      >
                        <option value="">Chọn thể loại</option>
                        {arrOptionSelect.category &&
                          arrOptionSelect.category?.map((row, i) => (
                            <option key={i} value={row.id}>
                              {row.name}
                            </option>
                          ))}
                      </select>
                      <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                    </div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Ghi chú</b>
                    </label>

                    <Input
                      value={arrItems?.note}
                      placeholder="Nhập ghi chú (nếu có)"
                      required
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrItems({ ...arrItems, note: e.target.value });
                      }}
                    />
                  </div>

                  <div className="lg:col-span-2">
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Chi tiết sản phẩm</b>
                    </label>

                    <Textarea
                      value={arrItems?.content}
                      type="text"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrItems({ ...arrItems, content: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* HÌNH ẢNH */}
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 border-gray-400 p-2">
                Hình ảnh về Sản Phẩm (Tổng tối đa 10 tập tin hình)
              </div>
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-2 p-3">
                <div>
                  <h3>
                    <b>Hinh ảnh </b>
                  </h3>
                  <h3 className="mt-4">
                    <b>* tối đa 10 hình</b>
                  </h3>
                  <div className="text-gray-500 text-sm">
                    <p>Hình đầu tiên (rìa trái) sẽ là hình đại diện </p>
                  </div>
                </div>

                <div className="col-span-2">
                  {imageURLS.length <= 10 && (
                    <Button onClick={onButtonClickFileImage}>CHỌN HÌNH ẢNH (KHÔNG VIDEO)</Button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    name="image"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onFilechange}
                  />{' '}
                  <div className="mt-1">Hiển thị ảnh ở đây</div>
                  <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
                    {checkInfo &&
                      imageURLsUpdate?.map((imageSrc, i) => (
                        <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                          <img src={`${API_URL}/imgshop?name=${imageSrc}`} alt="not fount" width={'100%'} />
                          <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                              <span onClick={() => removeImageUpdate(i)} className="cursor-pointer">
                                xóa
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                    {imageURLS?.map((imageSrc, i) => (
                      <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                        <img src={imageSrc} alt="not fount" width={'100%'} />
                        <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                          <div>
                            <span onClick={() => removeImage(i)} className="cursor-pointer">
                              xóa
                            </span>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2">
            {warningMessage && <p className="text-red-500 font-bold">{warningMessage}</p>}
            <Button
              color="orange"
              onClick={checkInfo ? subUpdateDefault : subAddDefault}
              disabled={isDisabled}
              loading={loading}
            >
              {checkInfo ? 'CẬP NHẬT' : 'LƯU THÊM MỚI'}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
