import images from '../../../assets/images';

function Camon() {
  return (
    <div className="flex justify-center items-center h-screen">
      <img src={images.camon} alt="camon" className="w-1/3 rounded-xl" />
    </div>
  );
}

export default Camon;
