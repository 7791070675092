import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { getCookie } from '../../../../utils/helper';
import { useEffect, useState } from 'react';
import { ChevronUpDownIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  addMenu,
  listAllMenu,
  listCountAllMenu,
  listIdMenu,
  removeMenu,
  updateMenu,
} from '../../../../services/apiCategory';

const TABLE_HEAD = ['STT', 'Tên', 'Vị trí', 'Link', 'Tác vụ'];

export default function MenuWeb() {
  const auth = getCookie('token');
  const [resultList, setResultList] = useState([]);
  const [arrDefault, setArrDefault] = useState({
    id_user: auth?.id,
    title: '',
    vitri: '',
    stt: 1,
    link: '',
    status: '1',
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [page, setPage] = useState(1);
  const [countDefault, setCountDefault] = useState(0);
  const [checkInfo, setCheckInfo] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const idCate = urlParams.get('u');
  const navigate = useNavigate();

  const listAll = async (pages = 1) => {
    const resultAPI = await listAllMenu(pages);
    if (resultAPI.length > 0) {
      setResultList(resultAPI);
      const count = await listCountAllMenu();
      setCountDefault(count.totalRows);
    }
  };

  useEffect(() => {
    document.title = 'Danh mục menu';
    listAll(1);
  }, []);

  useEffect(() => {
    getListID(idCate);
  }, [idCate]);

  const getListID = async (id) => {
    if (id) {
      const resultID = await listIdMenu(idCate);
      if (resultID.length > 0) {
        setCheckInfo(true);
        setArrDefault({
          id_user: auth?.id,
          title: resultID[0].title,
          link: resultID[0].link,
          vitri: resultID[0].vitri,
          stt: resultID[0].stt,
          status: resultID[0].status,
        });
      } else {
        setCheckInfo(false);
      }
    }
  };

  const changeValueAdmin = (jsonVL) => {
    setArrDefault({ ...arrDefault, ...jsonVL });
  };

  const subAddDefault = async () => {
    setIsDisabled(true);
    const resultAPI = await addMenu(arrDefault);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      setMessage('Thành Công');
      setOpenAlert(true);
      setArrDefault({ id_user: auth?.id, title: '', vitri: '', stt: 1, link: '', status: '1' });
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };
  const subUpdateDefault = async () => {
    setIsDisabled(true);
    const body = {
      id: idCate,
      data: arrDefault,
    };
    const resultAPI = await updateMenu(body);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      navigate('/menu');
      setMessage('Thành Công');
      setOpenAlert(true);
      setArrDefault({ id_user: auth?.id, name: '' });
      setCheckInfo(false);
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };

  const alertRemove = async (id) => {
    if (window.confirm(`Bạn đã chắc chắn XÓA dữ liệu này?`)) {
      // Save it!
      const body = {
        id: id,
      };
      const resultAPI = await removeMenu(body);
      if (resultAPI?.message === 'OK') {
        listAll(1);
      }
    } else {
      // Do nothing!
      console.log('Hủy yêu cầu');
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total > page) {
      setPage(page + 1);
      listAll(page + 1);
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listAll(page - 1);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div>
        <Card className="p-2">
          <h3 className="font-bold mb-1">THÊM Menu</h3>
          <Alert
            color={message === 'Thành Công' ? 'green' : 'red'}
            className="mb-2"
            open={openAlert}
            onClose={() => setOpenAlert(false)}
          >
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
            <div className="col-span-2">
              <Input
                value={arrDefault?.stt}
                label="Số Thứ Tự Hiển Thị"
                placeholder="Nhập số thứ tự hiển thị"
                required
                onChange={(e) => {
                  changeValueAdmin({ stt: e.target.value });
                }}
                type="number"
              />
            </div>
            <div className="col-span-2">
              <Input
                value={arrDefault?.title}
                label="Tên Menu"
                placeholder="Nhập tên menu"
                required
                onChange={(e) => {
                  changeValueAdmin({ title: e.target.value });
                }}
              />
            </div>
            <div className="col-span-2">
              {/* <label className="flex items-end gap-1 text-sm mb-1">
                <b>Vị trí</b>
              </label> */}
              <div className="relative mb-2">
                <select
                  value={`${arrDefault?.vitri}`}
                  onChange={(e) => {
                    changeValueAdmin({ vitri: e.target.value });
                  }}
                  className="w-full border-gray-400 rounded-lg bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                >
                  <option value="">Chọn vị trí</option>
                  <option value="header">Đầu trang (header)</option>
                  <option value="footer">Cuối trang (footer)</option>
                </select>
                <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
              </div>
            </div>
            <div className="col-span-2">
              <Input
                value={arrDefault?.link}
                label="LINK"
                placeholder="Nhập đường dẫn link"
                required
                onChange={(e) => {
                  changeValueAdmin({ link: e.target.value });
                }}
              />
            </div>
            <div>
              <Button
                className="bg-gradient-to-r from-orange-400 to-orange-900"
                onClick={checkInfo ? subUpdateDefault : subAddDefault}
                disabled={isDisabled}
              >
                {checkInfo ? 'CẬP NHẬT' : 'LƯU THÊM MỚI'}
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="h-full w-full">
          <CardBody className="overflow-auto px-0 p-1 rounded-3xl">
            <table className="w-full min-w-max table-auto">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {resultList.map((row, index) => {
                  const isLast = index === resultList.length - 1;
                  const classes = isLast ? 'p-2 text-center' : 'p-2 border-b border-blue-gray-50 text-center';

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {row.stt}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {row.title}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {row.vitri}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {row.link}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Tooltip content="Sửa">
                          <Link to={`/menu?u=${row.id}`}>
                            <IconButton variant="text">
                              <PencilIcon className="h-4 w-4" />
                            </IconButton>
                          </Link>
                        </Tooltip>

                        <Tooltip content={`Xóa`}>
                          <IconButton variant="text" onClick={() => alertRemove(row.id)}>
                            <TrashIcon className={`h-4 w-4 text-red-500`} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          {Math.ceil(countDefault / 50) > page && (
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Typography variant="small" color="blue-gray" className="font-normal">
                Page {page} of {Math.ceil(countDefault / 50)}
              </Typography>
              <div className="flex gap-2">
                <Button variant="outlined" size="sm" onClick={prevPage}>
                  Trang Trước
                </Button>
                <Button variant="outlined" size="sm" onClick={nextPage}>
                  Trang Tiếp
                </Button>
              </div>
            </CardFooter>
          )}
        </Card>
      </div>
    </div>
  );
}
