import { get, getSV, postSV, postUpload } from '../utils/https';

export const addItems = async (formData) => {
  try {
    const res = await postUpload(`/additems`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadFileSim = async (data) => {
  try {
    const res = await postUpload(`/uploadFileitems`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateItems = async (formData) => {
  try {
    const res = await postUpload(`/updateitems`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getIdItems = async (id) => {
  try {
    const res = await get(`/getiditems?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAllUser = async (page, stt, username, masp, cate) => {
  let userName = username ? `&username=${username}` : '';
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let cateSP = cate ? `&cate=${cate}` : '';
  let size = 50;
  try {
    const res = await getSV(`/listitemsuser?page=${page}&size=${size}${isStatus}${userName}${maSP}${cateSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllCountUser = async (stt, username, masp, cate) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let cateSP = cate ? `&cate=${cate}` : '';
  try {
    const res = await getSV(`/listitemsusercount?username=${username}${isStatus}${maSP}${cateSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAll = async (page, size, stt, cate, masp) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let cateSP = cate ? `&cate=${cate}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let sizeSP = size ? `&size=${size}` : '';
  try {
    const res = await getSV(`/listitemsall?page=${page}${sizeSP}${isStatus}${cateSP}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAllCount = async (stt, cate, masp) => {
  let cateSP = cate ? `&cate=${cate}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  try {
    const res = await getSV(`/listitemsallcount?is=${stt}${cateSP}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
