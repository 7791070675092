const images = {
  banner_login: require('./banner_login.jpg'),
  banner: require('./banner.png'),
  bg_body: require('./bg-body.png'),
  avt: require('./avt.png'),
  logo: require('./logo.png'),
  camon: require('./you.png'),
};

export default images;
