const routes = {
  home: '/',
  homeCart: '/gio-hang',
  checkRouters: '/:id',
  dashboard: '/dashboard',
  dashboardLogin: '/login',
  dashboardUser: '/user',
  dashboardChangePass: '/changepass',
  //Danh Mục
  dashboardCate: '/cate',
  dashboardMenu: '/menu',
  success: '/camon',
  //BĐS
  dashboardAddITEMS: '/additems',
  dashboardDsITEMS: '/dsitems',
  dashboardITEMSDetail: '/san-pham/:id',
  dashboardITEMS: '/san-pham-shop/:id',
};

export default routes;
