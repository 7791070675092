import routes from './configRoutes';
import DashboardLayout from '../layouts/DashboardLayout';
import DefaultLayout from '../layouts/DefaultLayout';
import MainLayout from '../layouts/MainLayout';
import { getCookie } from '../utils/helper';

//Page
import Dashboard from '../pages/Admin/Dashboard';
import Login from '../pages/Login';
import User from '../pages/Admin/User';
import ChangePassWord from '../pages/Admin/User/changePassWord';
import Home from '../pages/Main/Home';
import AddItems from '../pages/Admin/Items/addItems';
import DsItems from '../pages/Admin/Items/dsItems';
import Category from '../pages/Admin/Category/Cate';
import ItemsDetail from '../pages/Main/Home/ItemsDetail';
import Camon from '../pages/Main/Home/Camon';
import Cart from '../pages/Main/Cart';
import ListProducts from '../pages/Main/Home/ListProducts';
import MenuWeb from '../pages/Admin/Category/Menu';

//component pages

const token = getCookie('token');
//PUBLIC
const publicRoutes = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboardITEMSDetail, component: ItemsDetail, layout: MainLayout },
  { path: routes.dashboardLogin, component: Login, layout: DefaultLayout },
  { path: routes.checkRouters, component: Dashboard, layout: DashboardLayout },
  { path: routes.success, component: Camon, layout: DefaultLayout },
  { path: routes.homeCart, component: Cart, layout: MainLayout },
  { path: routes.dashboardITEMS, component: ListProducts, layout: MainLayout },
];

//PRIVATE
const privateRoutes = [
  { path: routes.home, component: Home, layout: MainLayout },
  { path: routes.dashboard, component: Dashboard, layout: DashboardLayout },
  { path: routes.dashboardUser, component: User, layout: DashboardLayout },
  { path: routes.dashboardChangePass, component: ChangePassWord, layout: DashboardLayout },
  { path: routes.dashboardCate, component: Category, layout: DashboardLayout },
  { path: routes.dashboardMenu, component: MenuWeb, layout: DashboardLayout },
  // BĐS
  { path: routes.dashboardAddITEMS, component: AddItems, layout: DashboardLayout },
  { path: routes.dashboardDsITEMS, component: DsItems, layout: DashboardLayout },
];

const privateRoutesAll = privateRoutes;

export { publicRoutes, privateRoutesAll };
