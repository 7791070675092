import { useNavigate, useParams } from 'react-router-dom';
import images from '../../../assets/images';
import { useEffect, useState } from 'react';
import { listProductIDAll } from '../../../services/apiItems';
import { API_URL } from '../../../utils/urls';
import { getLocalStorage, numberFormat, setLocalStorage } from '../../../utils/helper';
import { Alert, Button, Input } from '@material-tailwind/react';
import { checkUserNames, listUserID, listUserIDAll, sendMailShop } from '../../../services/apiUsers';

export default function ItemsDetail() {
  const [resultListItems, setResultListItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [loading, setLoading] = useState('');
  const [startX, setStartX] = useState(null);
  const [moveX, setMoveX] = useState(null);
  const { id } = useParams();

  const [statusOrder, setStatusOrder] = useState(false);
  const [messError, setMessError] = useState('');
  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [noteUser, setNoteUser] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [authDomain, setAuthDomain] = useState({});
  const [openCart, setOpenCart] = useState(false);
  const navigate = useNavigate();

  const listUserAll = async () => {
    const resultArrAll = await listProductIDAll(1, 1, '1', '', id);
    if (resultArrAll.length > 0) {
      setResultListItems(resultArrAll[0]);
    } else {
      setResultListItems([]);
      setLoading('BÁN');
    }
  };

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(1);
    if (resultAPISIM.length > 0) {
      setLocalStorage('info', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
    }
  };
  useEffect(() => {
    listUserAll();

    if (getLocalStorage('info')) {
      setAuthDomain(getLocalStorage('info'));
    } else {
      fetchApiUserID();
    }
  }, []);

  const fetchApiMail = async () => {
    const mail = {
      mail: authDomain['email'],
      nameuser: nameUser,
      namesanpham: resultListItems?.title,
      masanpham: 'KL' + resultListItems?.id,
      loaisanpham: resultListItems?.cate,
      phoneuser: phoneUser,
      addressuser: addressUser,
      price: numberFormat(resultListItems?.gia * selectedQuantity),
      noteuser: noteUser,
    };
    // console.log(mail);
    const resultAPI = await sendMailShop(mail);
    if (resultAPI.success === 'OK') {
      setNameUser('');
      setPhoneUser('');
      setAddressUser('');
      setNoteUser('');
      setStatusOrder(false);
      navigate('/camon');
    }
    console.log(resultAPI);
  };

  const handleImageChange = (index) => {
    setSelectedImage(index);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setMoveX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (moveX && startX) {
      const difference = startX - moveX;

      if (difference > 50) {
        if (selectedImage < resultListItems?.images?.split(',')?.length - 1) {
          setSelectedImage(selectedImage + 1);
        }
      } else if (difference < -50) {
        if (selectedImage > 0) {
          setSelectedImage(selectedImage - 1);
        }
      }

      setStartX(null);
      setMoveX(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nameUser.length < 4) {
      setMessError('Bạn nhập chưa đầy đủ họ tên');
    } else if (phoneUser.length !== 10) {
      setMessError('Bạn nhập chưa đúng số điện thoại liên hệ');
    } else if (addressUser.length < 15) {
      setMessError('Bạn nhập chưa đầy đủ địa chỉ ');
    } else {
      fetchApiMail();
      setStatusOrder(true);
    }
  };

  const handleUserName = (e) => {
    setNameUser(e.target.value);
    if (e.target.value.length >= 4) {
      setMessError('');
    }
  };

  const handlePhoneUser = (e) => {
    setPhoneUser(e.target.value);
    if (e.target.value.length === 10) {
      setMessError('');
    }
  };

  const handleAddressUser = (e) => {
    setAddressUser(e.target.value);
    if (e.target.value.length >= 15) {
      setMessError('');
    }
  };
  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, gia: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 1000);
  };
  return (
    <div className="container mx-auto">
      {loading === 'BÁN' ? (
        <b className="flex justify-center text-red-500">SẢN PHẨM KHÔNG TỒN TẠI HOẶC ĐÃ BÁN</b>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 p-2 gap-4">
          <div>
            <div
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{
                // margin: 5,
                transform: `translateX(${selectedImage}px)`,
                transition: 'transform 0.2s',
                width: '100%',
                height: 'auto',
              }}
            >
              <img
                src={`${API_URL}/imgshop?name=${
                  resultListItems?.images?.split(',').length > 0 && resultListItems?.images?.split(',')[selectedImage]
                }`}
                style={{ borderRadius: '10px' }}
                alt="Large Image"
                width={'100%'}
              />
            </div>
            <div className="flex">
              {resultListItems?.images?.split(',').length > 0 &&
                resultListItems?.images?.split(',').map((image, index) => (
                  <img
                    key={index}
                    src={`${API_URL}/imgshop?name=${image}`}
                    alt={`Small Image ${index + 1}`}
                    style={{
                      width: '50px',
                      height: '50px',
                      margin: '5px',
                      cursor: 'pointer',
                      border: selectedImage === index ? '1px solid red' : '1px solid #ccc',
                      borderRadius: '10px',
                    }}
                    onClick={() => handleImageChange(index)}
                  />
                ))}
            </div>
          </div>

          <div>
            <h1 className="text-green-500 font-bold text-xl mb-4">{resultListItems?.title}</h1>
            {resultListItems?.gia && (
              <div className="text-red-500 font-bold text-2xl mb-2">
                Giá sale: {numberFormat(resultListItems?.gia)}đ
              </div>
            )}
            {resultListItems?.cate && <div className="text-base mb-4">Loại: {resultListItems?.cate}</div>}
            {resultListItems?.note && (
              <div className="text-base p-1 border border-dashed border-deep-orange-500 mb-4">
                {resultListItems?.note}
              </div>
            )}
            {resultListItems?.soluong > 0 && (
              <div className="text-base grid grid-cols-2 lg:grid-cols-3 items-center gap-4 mb-4">
                <div>
                  <Input
                    type="number"
                    value={selectedQuantity}
                    required
                    className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    containerProps={{
                      className: 'min-w-[0px!important]',
                    }}
                    onChange={(e) => {
                      setSelectedQuantity(e.target.value);
                    }}
                  />
                </div>
                <div>Số Lượng</div>
                {resultListItems?.gia && authDomain?.email && (
                  <div>
                    <Button
                      color="orange"
                      onClick={() =>
                        onAddShop(
                          resultListItems?.title,
                          numberFormat(resultListItems.gia * selectedQuantity),
                          resultListItems?.cate,
                          selectedQuantity,
                          'KL' + resultListItems?.id,
                        )
                      }
                    >
                      Thêm giỏ hàng
                    </Button>
                  </div>
                )}
              </div>
            )}
            {openCart && (
              <div className="mb-2">
                <Alert variant="ghost" color="green">
                  <b>Đã thêm vào giỏ hàng</b>
                </Alert>
              </div>
            )}

            {resultListItems?.content && (
              <div
                className="text-base p-1 border border-dashed border-green-500 mb-4 rounded-lg"
                dangerouslySetInnerHTML={{
                  __html: resultListItems?.content ? resultListItems?.content.replace(/\n/g, '<br />') : '',
                }}
              />
            )}
            {authDomain?.phone && (
              <div className=" font-bold mb-4">
                Cần mua số lượng lớn? Gọi ngay <b className="text-green-500">{authDomain?.phone}</b>
              </div>
            )}
            {resultListItems?.gia && authDomain?.email && (
              <div className="grid border-2 border-red-400 rounded-md">
                <div className="font-bold border-b-2 border-red-400 p-2">ĐẶT HÀNG NGAY</div>
                <div className="grid grid-cols-1 p-3">
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Họ và tên</b>
                      </label>

                      <Input
                        value={nameUser}
                        placeholder="Nhập họ và tên"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={handleUserName}
                      />
                    </div>

                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Số điện thoại liên hệ</b>
                      </label>

                      <Input
                        value={phoneUser}
                        placeholder="Nhập số điện thoại (bắt buộc)"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={handlePhoneUser}
                      />
                    </div>

                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Địa chỉ giao hàng</b>
                      </label>

                      <Input
                        value={addressUser}
                        placeholder="Nhập địa chỉ (bắt buộc)"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={handleAddressUser}
                      />
                    </div>

                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Ghi chú</b>
                      </label>

                      <Input
                        value={noteUser}
                        placeholder="Nhập ghi chú (nếu có)"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => setNoteUser(e.target.value)}
                      />
                    </div>
                    {resultListItems?.gia && (
                      <div>
                        <span>
                          Tổng: số lượng {selectedQuantity} -{' '}
                          <b className="text-red-500">{numberFormat(resultListItems?.gia * selectedQuantity)}</b>
                        </span>
                      </div>
                    )}
                    <b className="text-red-500">{messError}</b>
                    <Button color="red" onClick={handleSubmit} disabled={statusOrder}>
                      ĐẶT MUA
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
