import { Alert, Button, Input } from '@material-tailwind/react';
import { checkLoginUsers, updateUserID } from '../../../services/apiUsers';
import { getCookie } from '../../../utils/helper';
import { useEffect, useState } from 'react';

export default function ChangePassWord() {
  const auth = getCookie('token');
  const [resultPassWord, setResultPassWord] = useState({ pass_old: '', pass_word: '', pass_new: '' });
  const [isPassOld, setIsPassOld] = useState(false);
  const [messagePassNew, setMessagePassNew] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = 'Đổi mật khẩu';
  }, []);

  const checkPassWordOld = async (user, pass) => {
    const dataUser = {
      user: user,
      pass: pass,
    };
    const checkLogin = await checkLoginUsers(dataUser);
    if (checkLogin.totalRow > 0) {
      //   setIsPassOld(false);
      setMessagePassNew('');
    } else {
      //   setIsPassOld(true);
      setMessagePassNew('Mật khẩu cũ không đúng');
    }
  };

  const handelUpdatePassWord = async () => {
    if (resultPassWord.pass_word === resultPassWord.pass_new) {
      const body = {
        id: auth.id,
        data: { password: resultPassWord.pass_new },
      };
      const resultAPISIM = await updateUserID(body);
      if (resultAPISIM?.message === 'OK') {
        setMessage('Thành Công');
        setResultPassWord({ pass_old: '', pass_word: '', pass_new: '' });
      } else {
        setMessage('Thất Bại');
      }
    } else {
      setMessagePassNew('Mật khẩu mới không trùng nhau');
    }
  };

  return (
    <div className="m-auto grid grid-cols-2 md:grid-cols-4">
      <div className="col-span-2">
        {message && (
          <Alert color={message === 'Thành Công' ? 'green' : 'red'} className="mb-2">
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
        )}
        <div className="mb-2">
          <label>Mật khẩu cũ</label>
          <Input
            type="password"
            value={resultPassWord?.pass_old || ''}
            placeholder="nhập mật khẩu cũ"
            onChange={(e) => {
              setResultPassWord({ ...resultPassWord, pass_old: e.target.value });
              setMessagePassNew('');
            }}
            required
          />
        </div>
        <div className="mb-2">
          <label>Mật khẩu mới</label>
          <Input
            type="password"
            value={resultPassWord?.pass_word || ''}
            placeholder="nhập mật khẩu mới"
            onChange={(e) => {
              setResultPassWord({ ...resultPassWord, pass_word: e.target.value });
              checkPassWordOld(auth?.username, resultPassWord?.pass_old);
              setMessagePassNew('');
            }}
            required
          />
        </div>
        <div className="mb-2">
          <label>Nhập lại mật khẩu mới</label>
          <Input
            type="password"
            value={resultPassWord?.pass_new || ''}
            placeholder="Nhập lại mật khẩu mới"
            onChange={(e) => {
              setResultPassWord({ ...resultPassWord, pass_new: e.target.value });
              setMessagePassNew('');
            }}
            required
          />
        </div>
        {messagePassNew && <p className="text-red-500 font-bold">{messagePassNew}</p>}

        <Button className="bg-orange-600" onClick={handelUpdatePassWord} disabled={messagePassNew.length > 0}>
          Cập Nhật
        </Button>
      </div>
    </div>
  );
}
