import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Card, Drawer, IconButton } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import images from '../../assets/images';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { getLocalStorage } from '../../utils/helper';
import { listAllMenu } from '../../services/apiCategory';

function MainLayout({ children }) {
  const [openDrawers, setOpenDrawers] = useState(false);
  const [menu, setMenu] = useState([]);
  const openDrawer = () => setOpenDrawers(true);
  const closeDrawer = () => setOpenDrawers(false);
  let addshop = getLocalStorage('addShop') || [];
  const location = useLocation();

  const listAll = async (pages = 1, vitri = 'header') => {
    const resultAPI = await listAllMenu(pages, 50, vitri);
    if (resultAPI.length > 0) {
      setMenu(resultAPI);
    }
  };

  useEffect(() => {
    listAll(1);
  }, []);
  return (
    <div
      className=" m-auto"
      style={{
        position: 'relative',
        zIndex: 20,
        backgroundImage: `url(${images.bg_body})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundSize: 'auto',
      }}
    >
      <div className={`z-[9999] w-full ${location.pathname !== '/' ? '' : 'fixed'}`}>
        <Card className="lg:hidden grid  w-full p-2 mb-2  rounded-none bg-black">
          <div className="grid grid-cols-3 items-center">
            <div>
              <IconButton variant="text" className="text-lg text-white" onClick={openDrawer}>
                <Bars3Icon className="h-10 w-10" />
              </IconButton>
            </div>
            <Link to="/">
              <div className="flex justify-center">
                <img src={images.logo} width={'60px'} className="rounded-2xl" />
              </div>
            </Link>
            <Link to="/gio-hang">
              <div className="flex justify-end items-center">
                {' '}
                <ShoppingCartIcon className="h-10 w-10 text-white text-right" />
                <b className="text-white">{addshop.length > 0 ? `(${addshop.length})` : ''}</b>
              </div>{' '}
            </Link>
          </div>
        </Card>
      </div>
      <header>
        <div className="container m-auto">
          <div className="relative z-[999] text-white font-bold font-sans text-[14px] p-3 ">
            <div className="hidden md:block">
              <Link to="/">
                <div className="flex justify-center">
                  <img src={images.logo} width={'150px'} className="rounded-2xl" />
                </div>
              </Link>
            </div>

            <div className="hidden md:block">
              <div className="flex gap-6 justify-center mt-6 text-[25px] font-[UTM-Avo]">
                {menu.length > 0 &&
                  menu.map((row, i) => (
                    <span
                      key={i}
                      className={`cursor-pointer ${location.pathname !== '/' ? 'text-black' : 'text-white'}`}
                    >
                      <Link to={row.link}>{row.title}</Link>
                    </span>
                  ))}
                <Link to="/gio-hang">
                  <span className={`cursor-pointer ${location.pathname !== '/' ? 'text-black' : 'text-white'}`}>
                    Giỏ hàng {addshop.length > 0 ? `(${addshop.length})` : ''}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>{' '}
      <div>{children}</div>
      <Drawer open={openDrawers} onClose={closeDrawer} className="p-4 bg-black">
        {menu.length > 0 &&
          menu.map((row, i) => (
            <div key={i}>
              <div className="mb-6 flex items-center justify-between py-3">
                <p className="font-serif text-white text-2xl">
                  {' '}
                  <Link to={row.link} onClick={closeDrawer}>
                    {row.title}
                  </Link>
                </p>
              </div>
              <hr className="my-3 border-blue-gray-500" />
            </div>
          ))}

        <Link to="/gio-hang">
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="font-serif text-white text-2xl">
              {' '}
              Giỏ hàng {addshop.length > 0 ? `(${addshop.length})` : ''}
            </p>
          </div>
        </Link>
      </Drawer>
    </div>
  );
}

export default MainLayout;
