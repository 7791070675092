import { useEffect, useState } from 'react';
import { listProductIDAll, listProductIDAllCount } from '../../../services/apiItems';
import { Link, useParams } from 'react-router-dom';
import { API_URL } from '../../../utils/urls';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Typography,
} from '@material-tailwind/react';
import { getLocalStorage, numberFormat, setLocalStorage } from '../../../utils/helper';
import images from '../../../assets/images';
import { ArrowLeftIcon, ArrowRightIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';

export default function ListProducts() {
  const { id } = useParams();
  const [resultListItems, setResultListItems] = useState({});
  const [countItems, setCountItems] = useState([]);
  const [page, setPage] = useState(1);
  const [openCart, setOpenCart] = useState(false);
  const [idAddShop, setIdAddShop] = useState(0);

  const listUserAll = async (pages = 1, size = '', stt = '1', cate = '') => {
    const resultArrAll = await listProductIDAll(pages, size, stt, cate);
    if (resultArrAll.length > 0) {
      setResultListItems(resultArrAll);
      const countItems = await listProductIDAllCount(stt, cate);
      setCountItems(countItems.totalRows);
    } else {
      setResultListItems([]);
    }
  };

  useEffect(() => {
    listUserAll(1, '', '1', id);
  }, []);

  const getItemProps = (index) => ({
    variant: page === index ? 'filled' : 'text',
    color: 'gray',
    onClick: () => {
      setPage(index);
      listUserAll(index, '', '1', id);
      scrollToTop();
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const next = () => {
    if (page === countItems) return;

    const total = Math.ceil(countItems / 50);
    if (total > page) {
      setPage(page + 1);
      listUserAll(page + 1, '', '1', id);
      scrollToTop();
    }
  };

  const prev = () => {
    if (page === countItems) return;

    const total = Math.ceil(countItems / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listUserAll(page - 1, '', '1', id);
      scrollToTop();
    }
  };

  const pagesToShow = 5;
  const totalNumbers = Array.from(new Array(Math.ceil(countItems / 50)), (val, index) => index + 1);

  const firstPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  const lastPage = Math.min(Math.ceil(countItems / 50), firstPage + pagesToShow - 1);

  const prevPages = firstPage > 1 ? [1, '...'] : [];
  const nextPages = lastPage < Math.ceil(countItems / 50) ? ['...', Math.ceil(countItems / 50)] : [];

  const pageNumbers = [...prevPages, ...totalNumbers.slice(firstPage - 1, lastPage), ...nextPages];

  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, gia: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 1000);
  };
  return (
    <div className="container m-auto">
      <div className={`grid justify-center justify-items-center`}>
        <h2 className="text-2xl lg:text-4xl font-bold text-green-700 mb-10">
          {resultListItems.length > 0 && resultListItems[0]?.cate}
        </h2>
        <div className="grid grid-cols-1  lg:grid-cols-4 gap-2">
          {resultListItems.length > 0 &&
            resultListItems?.map((item, index) => (
              <div className="p-4" key={index}>
                <Card>
                  <CardHeader color="blue-gray" className="relative">
                    <LazyLoadImage
                      alt={'không có ảnh'}
                      width={'100%'}
                      effect="blur"
                      src={`${
                        item?.images?.split(',').length > 0
                          ? `${API_URL}/imgshop?name=${item?.images?.split(',')[0]}`
                          : images.logo
                      }`}
                      onError={(e) => {
                        e.target.src = images.logo;
                      }}
                      className="rounded-xl"
                    />
                  </CardHeader>
                  <CardBody>
                    <div className="mb-2 font-[UTM-Avo] line-clamp-4 lg:line-clamp-2 font-bold">{item?.title}</div>
                    <Typography variant="h5" color="red" className="font-[UTM-Avo]">
                      {numberFormat(item?.gia)} đ
                    </Typography>
                  </CardBody>
                  <CardFooter className="pt-0 gap-2 flex ">
                    <Link to={`/san-pham/${item?.id}`}>
                      <Button color="orange" size="sm">
                        Xem chi tiết
                      </Button>
                    </Link>
                    <Button
                      color="green"
                      size="sm"
                      onClick={() => {
                        onAddShop(item?.title, numberFormat(item.gia), item?.cate, 1, 'KL' + item?.id);
                        setIdAddShop(item?.id);
                      }}
                    >
                      <ShoppingCartIcon className="h-4 w-4" />
                    </Button>
                  </CardFooter>
                  {openCart && idAddShop === item?.id && (
                    <div className="mb-2">
                      <Alert variant="ghost" color="green">
                        <b className="text-sm">Đã thêm vào giỏ hàng</b>
                      </Alert>
                    </div>
                  )}
                </Card>
              </div>
            ))}
        </div>
        <hr className="my-4 border-blue-gray-50" />
        {resultListItems.length > 0 && (
          <div className="flex items-center justify-center mb-4">
            <div>
              <Button variant="text" size="sm" className="flex items-center gap-1" onClick={prev} disabled={page === 1}>
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Trước
              </Button>
            </div>
            <div className="flex">
              {pageNumbers.map((number, index) =>
                number !== '...' ? (
                  <IconButton key={index} {...getItemProps(number)} size="sm">
                    {number}
                  </IconButton>
                ) : (
                  <IconButton key={index} variant="text" size="sm">
                    {number}
                  </IconButton>
                ),
              )}
            </div>

            <div>
              <Button
                variant="text"
                size="sm"
                className="flex items-center gap-1"
                onClick={next}
                disabled={page === Math.ceil(countItems / 50)}
              >
                Sau
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
