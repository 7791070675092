import { BuildingOffice2Icon, CheckBadgeIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/solid';
import { Button, Card, CardBody, CardFooter, CardHeader, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCookie } from '../../../utils/helper';

export default function Dashboard() {
  const auth = getCookie('token');
  useEffect(() => {
    document.title = 'Hệ Thống';
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
      <Card className="mt-6 border">
        <CardBody>
          <div className="grid justify-items-center">
            <UserIcon className="w-24 h-24 text-gray-400" />
            <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
              Quản Lý Thông Tin
            </Typography>
            <Typography>Quản lý thông tin cá nhân. Cập nhật ảnh đại diện, thông tin ...</Typography>
          </div>
        </CardBody>
        <CardFooter className="pt-0 m-auto">
          <Link to="/user">
            <Button color="cyan">Chi tiết</Button>
          </Link>
        </CardFooter>
      </Card>

      <Card className="mt-6 border">
        <CardBody>
          <div className="grid justify-items-center">
            <BuildingOffice2Icon className="w-24 h-24 text-gray-400" />
            <Typography variant="h5" color="blue-gray" className="mb-2 font-[UTM-Avo]">
              Quản Lý Sản Phẩm
            </Typography>
            <Typography>
              Quản lý sản phẩm <br />
              Thêm - Sửa - Xóa Sản Phẩm của bạn
            </Typography>
          </div>
        </CardBody>
        <CardFooter className="pt-0 m-auto">
          <Link to="/dsitems">
            <Button color="orange">Chi tiết</Button>
          </Link>
        </CardFooter>
      </Card>
    </div>
  );
}
