import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLocalStorage, numberFormat, setLocalStorage } from '../../../utils/helper';
import { Alert, Button, Input, Typography } from '@material-tailwind/react';
import { listUserID, sendMailShop } from '../../../services/apiUsers';
import { TrashIcon } from '@heroicons/react/24/solid';

export default function Cart() {
  const [resultListItems, setResultListItems] = useState([]);
  const TABLE_HEAD = ['Tên Sản Phẩm', 'Loại', 'Số Lượng', 'Giá', ''];

  const [statusOrder, setStatusOrder] = useState(false);
  const [messError, setMessError] = useState('');
  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [noteUser, setNoteUser] = useState('');
  const [authDomain, setAuthDomain] = useState({});
  const navigate = useNavigate();

  const listUserAll = async () => {
    const resultArrAll = getLocalStorage('addShop') || [];
    if (resultArrAll.length > 0) {
      setResultListItems(resultArrAll);
    } else {
      setResultListItems([]);
    }
  };

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(1);
    if (resultAPISIM.length > 0) {
      setLocalStorage('info', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
    }
  };
  useEffect(() => {
    listUserAll();

    if (getLocalStorage('info')) {
      setAuthDomain(getLocalStorage('info'));
    } else {
      fetchApiUserID();
    }
  }, []);

  const fetchApiMail = async () => {
    const text = resultListItems
      .map((item) => `<br/>✔️${item.title} - soluong: ${item.soluong} - ${item.cate} - ${numberFormat(item.gia)}`)
      .join('');
    const mail = {
      mail: authDomain['email'],
      nameuser: nameUser,
      namesanpham: text,
      masanpham: '',
      loaisanpham: '',
      phoneuser: phoneUser,
      addressuser: addressUser,
      price: numberFormat(getTotalPrice(resultListItems)),
      noteuser: noteUser,
    };
    // console.log(mail);
    const resultAPI = await sendMailShop(mail);
    if (resultAPI.success === 'OK') {
      setNameUser('');
      setPhoneUser('');
      setAddressUser('');
      setNoteUser('');
      setLocalStorage('addShop', []);
      setStatusOrder(false);
      navigate('/camon');
    }
    console.log(resultAPI);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nameUser.length < 4) {
      setMessError('Bạn nhập chưa đầy đủ họ tên');
    } else if (phoneUser.length !== 10) {
      setMessError('Bạn nhập chưa đúng số điện thoại liên hệ');
    } else if (addressUser.length < 15) {
      setMessError('Bạn nhập chưa đầy đủ địa chỉ ');
    } else {
      fetchApiMail();
      setStatusOrder(true);
    }
  };

  const handleUserName = (e) => {
    setNameUser(e.target.value);
    if (e.target.value.length >= 4) {
      setMessError('');
    }
  };

  const handlePhoneUser = (e) => {
    setPhoneUser(e.target.value);
    if (e.target.value.length === 10) {
      setMessError('');
    }
  };

  const handleAddressUser = (e) => {
    setAddressUser(e.target.value);
    if (e.target.value.length >= 15) {
      setMessError('');
    }
  };

  const getTotalPrice = (data) => {
    let totalPrice = 0;

    data.forEach((item) => {
      if (item.gia && !isNaN(item.gia.replace('.', ''))) {
        totalPrice += parseFloat(item.gia.replace('.', ''));
      }
    });

    return totalPrice;
  };

  const getTotalSoluong = (data) => {
    let totalQuatity = 0;

    data.forEach((item) => {
      if (item.soluong && !isNaN(item.soluong?.toString().replace('.', ''))) {
        totalQuatity += parseFloat(item.soluong?.toString().replace('.', ''));
      }
    });

    return totalQuatity;
  };

  const removePrice = (index) => {
    const newData = resultListItems.slice();
    newData.splice(index, 1);
    setLocalStorage('addShop', newData);
    setResultListItems(newData);
  };
  return (
    <div className="container mx-auto">
      {resultListItems.length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 p-2 gap-4">
          <div className="border border-dashed border-green-500 rounded-xl">
            <table className="w-full table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th key={head} className="p-4 pt-10">
                      <Typography variant="small" color="blue-gray" className="font-bold leading-none">
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {resultListItems.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td className="p-4">
                        <Typography variant="small" color="blue-gray" className="font-bold">
                          {row.title}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" className="font-normal text-gray-600">
                          {row.cate}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" className="font-normal text-gray-600">
                          {row.soluong}
                        </Typography>
                      </td>
                      <td className="p-4">
                        <Typography variant="small" className="font-normal text-gray-600">
                          {row.gia}
                        </Typography>
                      </td>
                      <td>
                        <TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" onClick={() => removePrice(i)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot className="border-t border-gray-300">
                <tr>
                  <td className="p-4">
                    <Typography color="blue-gray" variant="small" className="font-bold">
                      Tổng
                    </Typography>
                  </td>
                  <td className="p-4"></td>
                  <td className="p-4">
                    <Typography color="blue-gray" variant="small" className="font-bold">
                      {getTotalSoluong(resultListItems)}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography color="blue-gray" variant="small" className="font-bold">
                      {numberFormat(getTotalPrice(resultListItems))}
                    </Typography>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div>
            {resultListItems.length > 0 && authDomain?.email && (
              <div className="grid border-2 border-red-400 rounded-md">
                <div className="font-bold border-b-2 border-red-400 p-2">ĐẶT HÀNG NGAY</div>
                <div className="grid grid-cols-1 p-3">
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Họ và tên</b>
                      </label>

                      <Input
                        value={nameUser}
                        placeholder="Nhập họ và tên"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={handleUserName}
                      />
                    </div>

                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Số điện thoại liên hệ</b>
                      </label>

                      <Input
                        value={phoneUser}
                        placeholder="Nhập số điện thoại (bắt buộc)"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={handlePhoneUser}
                      />
                    </div>

                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Địa chỉ giao hàng</b>
                      </label>

                      <Input
                        value={addressUser}
                        placeholder="Nhập địa chỉ (bắt buộc)"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={handleAddressUser}
                      />
                    </div>

                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>Ghi chú</b>
                      </label>

                      <Input
                        value={noteUser}
                        placeholder="Nhập ghi chú (nếu có)"
                        required
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => setNoteUser(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>
                        Tổng:
                        <b className="text-red-500"> {numberFormat(getTotalPrice(resultListItems))}đ</b>
                      </span>
                    </div>
                    <b className="text-red-500">{messError}</b>
                    <Button color="red" onClick={handleSubmit} disabled={statusOrder}>
                      ĐẶT MUA
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <b className="flex justify-center text-red-500">KHÔNG CÓ SẢN PHẨM NÀO TRONG GIỎ HÀNG</b>
      )}
    </div>
  );
}
