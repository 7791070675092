import {
  ArrowRightCircleIcon,
  HomeModernIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { BellAlertIcon, EyeIcon, PencilIcon, TrashIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/solid';
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
  Checkbox,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Alert,
} from '@material-tailwind/react';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  listProductIDAllCountUser,
  listProductIDAllUser,
  updateItems,
  uploadFileSim,
} from '../../../services/apiItems';
import { getCookie, numberFormat, numberFormatTextShorten } from '../../../utils/helper';
import { API_URL } from '../../../utils/urls';

const TABLE_HEAD = ['Mã SP', 'Tiêu đề', 'Giá', 'Số lượng', 'Ngày tạo', 'Tác vụ'];

const TABS = [
  {
    label: 'Tin Đã Duyệt',
    value: '',
  },
  // {
  //   label: 'Tin Chờ Duyệt',
  //   value: 3,
  // },
];

export default function DsItems() {
  const auth = getCookie('token');
  const [resultListBds, setResultListBds] = useState([]);
  const [page, setPage] = useState(1);
  const [countChoDuyet, setCountChoDuyet] = useState('');
  const [countBds, setCountBds] = useState(0);
  const [objBoLoc, setObjBoLoc] = useState({ stt: '', username: auth?.id, code: '' });
  const [openDetail, setOpenDetail] = useState(false);
  const [objDetail, setObjDetail] = useState(false);
  const [message, setMessage] = useState('');

  const handleOpen = () => setOpenDetail(!openDetail);

  const listUserAll = async (pages = 1, stt = '', username = '', code = '') => {
    const resultBDSAll = await listProductIDAllUser(pages, stt, username, code);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      const countBds = await listProductIDAllCountUser(stt, username, code);
      setCountBds(countBds.totalRows);
    } else {
      setResultListBds([]);
    }
  };

  useEffect(() => {
    listUserAll(1, '', auth?.id);
    document.title = 'Danh sách sản phẩm';
  }, []);

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      listUserAll(1, objBoLoc.stt, objBoLoc.username, laySoTuDoanText(objBoLoc.code));
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countBds / 50);
    if (total > page) {
      setPage(page + 1);
      listUserAll(page + 1, objBoLoc.stt, objBoLoc.username, laySoTuDoanText(objBoLoc.code));
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countBds / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listUserAll(page - 1, objBoLoc.stt, objBoLoc.username, laySoTuDoanText(objBoLoc.code));
    }
  };

  const alertRemoveUser = async (id, status) => {
    if (window.confirm(status === '1' ? `Bạn đã chắc chắn XÓA` : ``)) {
      // Save it!
      const formData = new FormData();
      const body = { id: id, status: status === '1' ? '2' : '1' };
      formData.append('data', JSON.stringify(body));
      //   formData.append('imgremove', JSON.stringify([]));
      const resultAPISIM = await updateItems(formData);
      if (resultAPISIM?.message === 'OK') {
        listUserAll(page, objBoLoc.stt, objBoLoc.username, laySoTuDoanText(objBoLoc.code));
      }
    } else {
      // Do nothing!
      console.log('Hủy yêu cầu');
    }
  };

  function laySoTuDoanText(text) {
    var match = text?.match(/\d+/); // Sử dụng regular expression để tìm các số trong text
    return match ? match[0] : null;
  }

  return (
    <div>
      {/* <Dialog open={openDetail} handler={handleOpen}>
        <DialogHeader className="font-[UTM-Avo]">{objDetail?.title && objDetail?.title}</DialogHeader>
        <DialogBody className="h-[42rem] overflow-scroll leading-8 font-[500]">
          <div className="text-gray-900 font-[UTM-Avo] ">
            <p>
              <b>Giá</b>: {numberFormatTextShorten(objDetail?.gia)}
            </p>

            <p>
              <b>Số lượng</b>: {objDetail?.soluong}
            </p>
            <p>
              <b>Thể loại</b>: {objDetail?.cate}
            </p>

            <p>
              <b>Ghi chú</b>: {objDetail?.note}
            </p>

            <p>
              <b>Nội dung</b>: {objDetail?.content}
            </p>

            <p>
              <b>Ảnh: </b>
            </p>
            <div className="grid grid-cols-5 gap-2">
              {objDetail.images?.split(',').length > 0 &&
                objDetail.images?.split(',').map((imageSrc, i) => (
                  <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                    <img src={`${API_URL}/imgshop?name=${imageSrc}`} alt="not fount" width={'100%'} />
                  </figure>
                ))}
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Đóng</span>
          </Button>
        </DialogFooter>
      </Dialog> */}
      <Card className="w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <div className="text-xl font-bold">DANH SÁCH SẢN PHẨM</div>
              {/* <Typography color="gray" className="mt-1 font-normal">
              Tất cả nhân viên đang hoạt động và đã nghỉ
            </Typography> */}
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {/* <Button variant="outlined" size="sm">
              view all
            </Button> */}
              <Link to="/additems">
                <Button className="flex items-center gap-3 bg-green-600" size="sm">
                  <HomeModernIcon strokeWidth={2} className="h-4 w-4" /> Thêm Mới
                </Button>
              </Link>
            </div>
          </div>
          {message === 'Loading' && <b>Đang thêm dữ liệu....</b>}
          {message === 'Thành Công' && (
            <Alert
              icon={<BellAlertIcon className="h-5 w-5" />}
              className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946] flex items-center"
            >
              <b>THÊM TIN THÀNH CÔNG</b>
            </Alert>
          )}

          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            {/* <Tabs value={''} className="w-full md:w-96">
              <TabsHeader>
                {TABS.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => {
                      listUserAll(1, value, objBoLoc.username, objBoLoc.code);
                      setObjBoLoc({ ...objBoLoc, stt: value });
                    }}
                  >
                    &nbsp;&nbsp;{`${label}${value === 3 ? `${countChoDuyet ? `(${countChoDuyet})` : ''}` : ''}`}
                    &nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs> */}
            <div className="w-full md:w-96">
              <Input
                label="Tìm kiếm theo mã sản phẩm"
                value={objBoLoc?.code}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                onChange={(e) => setObjBoLoc({ ...objBoLoc, code: e.target.value })}
                onKeyPress={handleEnterKey}
              />
              <Typography variant="small" color="gray" className="mt-2 flex items-center gap-1 font-normal">
                <InformationCircleIcon className="h-4 w-4" />
                Nhập Mã Sản Phẩm tìm kiếm và sau đó ấn enter
              </Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-auto px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                    <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resultListBds.map((row, index) => {
                const isLast = index === resultListBds.length - 1;
                const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <label className="font-normal text-base">
                            <b>{`KL${row.id}`}</b>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col w-72">{row.title}</div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <label className="text-sm font-bold text-red-500">{numberFormat(row.gia)}</label>
                        <label className="font-normal text-sm opacity-70">{numberFormatTextShorten(row.gia)}</label>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">{row.soluong}</div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <label className="font-normal text-sm ">{row?.update_date}</label>
                        <label className="font-normal text-sm opacity-70">{row?.create_date}</label>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center">
                        <Tooltip content="Sửa tin">
                          <Link to={`/additems?u=${row.id}`}>
                            <IconButton variant="text">
                              <PencilIcon className="h-4 w-4" />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip content={`Xem chi tiết`}>
                          {row.status === '3' ? (
                            <IconButton
                              variant="text"
                              onClick={() => {
                                handleOpen();
                                setObjDetail(row);
                              }}
                            >
                              <EyeIcon className={`h-4 w-4 text-blue-600`} />
                            </IconButton>
                          ) : (
                            <Link to={`/san-pham/${row.id}`}>
                              <IconButton variant="text">
                                <EyeIcon className={`h-4 w-4 text-blue-600`} />
                              </IconButton>
                            </Link>
                          )}
                        </Tooltip>
                        {row.status !== '3' && (
                          <Tooltip content={`Xóa sản phẩm`}>
                            <TrashIcon
                              className="h-5 w-5 text-red-500 cursor-pointer"
                              onClick={(e) => {
                                alertRemoveUser(row.id, row.status);
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {Math.ceil(countBds / 50) > page && (
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography variant="small" color="blue-gray" className="font-normal">
              Page {page} of {Math.ceil(countBds / 50)}
            </Typography>
            <div className="flex gap-2">
              <Button variant="outlined" size="sm" onClick={prevPage}>
                Trang Trước
              </Button>
              <Button variant="outlined" size="sm" onClick={nextPage}>
                Trang Tiếp
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
