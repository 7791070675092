import { useEffect, useRef, useState } from 'react';
import { Alert, Avatar, Button, Card, Chip, IconButton, Input, Menu, MenuHandler } from '@material-tailwind/react';
import images from '../../../assets/images';
import { getCookie, removeCookie, setCookie } from '../../../utils/helper';
import {
  ClockIcon,
  LockClosedIcon,
  PencilSquareIcon,
  PhotoIcon,
  UserCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { KeyIcon } from '@heroicons/react/24/outline';
import { API_URL } from '../../../utils/urls';
import { listUserID, updateImageLogo, updateUserID } from '../../../services/apiUsers';
import { Link } from 'react-router-dom';

export default function User() {
  const auth = getCookie('token');
  const inputFile = useRef(null);
  const [imagesLogo, setImagesLogo] = useState({});
  const [resultAdminID, setResultAdminID] = useState({
    name: auth?.name,
    email: auth?.email,
    phone: auth?.phone,
    address: auth?.address,
  });
  const [messageImageSize, setMessageImageSize] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = 'Thông tin cá nhân';
  }, []);

  const onFilechange = (e) => {
    e.preventDefault();
    let imagesss = e.target.files[0];
    if (imagesss.size <= 1048576) {
      let objImage = {
        image: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      };
      setImagesLogo(objImage);
      setMessageImageSize('');
    } else {
      setMessageImageSize('Kích thước ảnh không được vượt quá 1MB.');
    }
  };

  const onButtonClickFileImageLogo = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const submitUpdateLogo = async () => {
    const formData = new FormData();
    formData.append('image', imagesLogo?.image);
    formData.append('name', `avt_${auth.id}`);
    const upload = await updateImageLogo(formData);
    if (upload.message === 'OK') {
      setImagesLogo({ ...imagesLogo, image: '' });
    }
  };

  const changeValueAdmin = (jsonVL) => {
    setResultAdminID({ ...resultAdminID, ...jsonVL });
  };

  const editProfile = async () => {
    const body = {
      id: auth.id,
      data: resultAdminID,
    };
    const resultAPISIM = await updateUserID(body);
    if (resultAPISIM?.message === 'OK') {
      setMessage('Thành Công');
      setIsDisabled(true);
      const resultAPISIMID = await listUserID(auth.id);
      removeCookie('token');
      setCookie('token', resultAPISIMID[0]);
    } else {
      setMessage('Thất Bại');
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-6">
      <div className="sm:col-span-6 lg:col-span-2">
        <div className="flex items-center justify-center">
          <div className="relative inline-flex cursor-pointer" onClick={onButtonClickFileImageLogo}>
            {imagesLogo?.url ? (
              <img src={imagesLogo?.url} className="rounded-full" width={'75px'} height={'75px'} alt="logo" />
            ) : (
              <img
                className="rounded-full"
                src={`${API_URL}/avt?name=avt_${auth.id}.jpg`}
                width={'75px'}
                height={'75px'}
                onError={(e) => {
                  e.target.src = images.avt;
                }}
              />
            )}
            <span className="absolute bottom-[-15px] left-[-24px] grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-500 py-1 px-1 text-xs font-medium leading-none text-white content-['']">
              {' '}
              Thay ảnh
            </span>
            <input type="file" name="image" id="file" ref={inputFile} className="hidden" onChange={onFilechange} />
            <b className="text-red-500"> {messageImageSize}</b>
          </div>

          <div className="ml-2">
            {imagesLogo?.image && messageImageSize === '' && (
              <Button size="sm" className="bg-light-green-800" onClick={submitUpdateLogo}>
                Lưu lại
              </Button>
            )}
          </div>
        </div>
        <br />
        <div className="flex items-center justify-center flex-col">
          <label className="p-2 rounded-md bg-gray-200 text-gray-800 border border-gray-400">
            {' '}
            {auth.username && 'User_ID: ' + auth.username}
          </label>{' '}
          <p className="mt-2">{auth.name && 'Họ tên: ' + auth.name}</p>
          <p className="mt-2">
            {auth.name && auth.permission === 1
              ? 'Chức Danh: Quản Trị'
              : auth.permission === 2
              ? 'Chức Danh: Kiểm Duyệt Viên'
              : auth.permission === 3
              ? 'Chức Danh: Quản Lý'
              : 'Chức Danh: Sale'}
          </p>
          <p className="mt-2">{auth.email && 'Email: ' + auth.email}</p>
          <div className="mt-4 leading-8 ">
            {/* <div className="flex items-center gap-2 cursor-pointer">
            <UserCircleIcon className="h-5 w-5" />
            <b>Chỉnh sửa thông tin</b>
          </div> */}
            <Link to="/changepass">
              <div className="flex items-center gap-2 cursor-pointer mb-4">
                <KeyIcon className="h-5 w-5" />
                <b>Đổi mật khẩu</b>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:col-span-3">
        {message && (
          <Alert color={message === 'Thành Công' ? 'green' : 'red'} className="mb-2">
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
        )}
        <Card className="p-2">
          <div className="flex justify-between">
            <h2 className="font-bold mb-2">THÔNG TIN CÁ NHÂN</h2>
            <IconButton variant="outlined" color="blue-gray" className="flex items-center gap-2">
              {isDisabled && (
                <PencilSquareIcon
                  className="h-5 w-5"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                />
              )}
              {!isDisabled && (
                <XCircleIcon
                  className="h-5 w-5"
                  onClick={() => {
                    setIsDisabled(true);
                  }}
                />
              )}
            </IconButton>
          </div>
          <div className="mb-2">
            <label>Họ và Tên</label>
            <Input
              value={resultAdminID.name}
              disabled={isDisabled}
              onChange={(e) => {
                changeValueAdmin({ name: e.target.value });
              }}
            />
          </div>
          <div className="mb-2">
            <label>Email</label>
            <Input
              value={resultAdminID.email}
              disabled={isDisabled}
              onChange={(e) => {
                changeValueAdmin({ email: e.target.value });
              }}
            />
          </div>
          <div className="mb-2">
            <label>Số điện thoại</label>
            <Input
              value={resultAdminID.phone}
              disabled={isDisabled}
              onChange={(e) => {
                changeValueAdmin({ phone: e.target.value });
              }}
            />
          </div>
          <div className="mb-2">
            <label>Địa chỉ</label>
            <Input
              value={resultAdminID.address}
              disabled={isDisabled}
              onChange={(e) => {
                changeValueAdmin({ address: e.target.value });
              }}
            />
          </div>
          {!isDisabled && (
            <div className="mb-2">
              <Button size="sm" className="bg-light-green-800 w-full" onClick={editProfile}>
                Cập Nhật
              </Button>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
